import React, { Component } from "react";
import i18n from "../../config/i18n";

import "./Header.scss";

class Header extends Component {
  render() {
    let logo: string = "logo_gauche_violet.png";
    return (
      <div className={"d-none d-md-block"}>
        <img
          src={
            process.env.PUBLIC_URL +
            "/images/logo/" +
            i18n.language +
            "/" +
            logo
          }
          alt="Logo"
          width="300"
        />
      </div>
    );
  }
}

export default Header;
