import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../../../config/i18n";

import { Form, Button } from "react-bootstrap";

import "./style.scss";

const CGU = (props) => {
  const { t, onClickNext } = props;

  const isMobile = window.innerWidth < 768;

  return (
    <div className="CGU d-flex flex-column align-items-center justify-content-center h-100 mx-auto">
      {isMobile && (
        <div className="CGU__logo">
          <img
            src={`/images/logo/${i18n.language}/logo_gauche_blanc.png`}
            alt="logo"
          />
        </div>
      )}
      <div className="CGU__container p-4 m-2 m-md-0">
        <div className="CGU__container__title mb-4">
          {t("landing.CGU.title")}
        </div>

        <div className="CGU__container__body mb-4">
          <div className="body_content m-col-16-24 xl-col-12-24">
            <div className="div_content">
              <div className="page m-b-bl" id="mainoverview">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2 className="CollectingYourInfo" id="mainoverview_Header">
                    Introduction
                  </h2>
                  <h2 className="CollectingYourInfoRightNav">Vue d’ensemble</h2>
                </div>
                <hr />
                <div id="mainoverview_ShortDescription">
                  <label>
                    <span>
                      <p>
                        La confidentialité de vos informations est importante
                        pour Microsoft («&nbsp;nous&nbsp;», «&nbsp;nos&nbsp;»,
                        «&nbsp;notre&nbsp;» ou «&nbsp;Microsoft&nbsp;»). Nous
                        respectons le droit à la vie privée de tous les
                        individus et nous engageons à traiter les données
                        personnelles de manière responsable et conformément aux
                        lois applicables et aux principes{" "}
                        <a href="#mainepp">
                          de protection de la vie privée des employés
                        </a>
                        . L’objectif de cette déclaration sur la protection des
                        données personnelles, de ses addenda et de tout autre
                        document indiqué ou affiché lors de la collecte de
                        données est de vous expliquer quelles sont les données
                        personnelles collectées par Microsoft, comment ces
                        données personnelles sont utilisées et quels sont vos
                        droits sur ces données personnelles.
                      </p>
                      <p>
                        Veuillez noter que cette déclaration sur la protection
                        des données personnelles s’applique au traitement de vos
                        données personnelles en tant qu’employé, ancien employé,
                        candidat, invité ou personnel externe. (Le
                        «&nbsp;Personnel externe&nbsp;» regroupe les
                        travailleurs qui ne sont pas employés par Microsoft,
                        mais qui ont accès aux installations de Microsoft et/ou
                        au réseau de l’entreprise Microsoft. Cette catégorie
                        englobe les intérimaires, travailleurs détachés,
                        sous-traitants et intervenants professionnels). De plus,
                        Microsoft s’impose un niveau de sécurité supplémentaire
                        pour la protection de la vie privée et des données
                        personnelles de ses employés.
                      </p>
                      <p>
                        Cette déclaration ne concerne pas votre utilisation
                        personnelle de produits de consommation de Microsoft ou
                        en dehors de votre travail ou de vos tâches effectuées
                        pour le compte de Microsoft. Les produits de
                        consommation de Microsoft peuvent inclure des services,
                        des sites web, des applications, des logiciels, des
                        serveurs et des appareils. Si vous souhaitez en savoir
                        plus sur la façon dont Microsoft collecte des données
                        sur votre utilisation des produits Microsoft en tant que
                        consommateur, veuillez lire la{" "}
                        <a href="https://go.microsoft.com/fwlink/?LinkID=521839">
                          Déclaration de confidentialité Microsoft
                        </a>
                        .
                      </p>
                      <p>
                        Le présent Avis ne constitue en aucun cas une promesse
                        ou un contrat, explicite ou implicite, d’embauche, de
                        rémunération ou de traitement spécifique dans des
                        situations particulières. Il ne doit pas être interprété
                        en ce sens. Le présent Avis ne peut être interprété pour
                        empêcher Microsoft de traiter les données de ses
                        employés à des fins de se conformer à ses obligations
                        légales, ou pour enquêter sur des allégations
                        d’inconduite ou de violation de la politique ou du droit
                        de l’entreprise, sous réserve du respect des
                        réglementations locales en vigueur.
                      </p>
                      <p>
                        Le traitement des données personnelles par Microsoft
                        est, dans tous les cas, soumis aux exigences de la
                        législation locale applicable, de la politique interne
                        et, le cas échéant, de toute exigence de consultation
                        des représentants des travailleurs. En cas de
                        contradiction entre cette déclaration et la législation
                        locale en vigueur dans votre juridiction, cette dernière
                        prévaut.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainpersonaldatathatweprocess">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainpersonaldatathatweprocess_Header"
                  >
                    Données personnelles que nous traitons (il peut s’agir de
                    données que vous nous communiquez, que nous collectons ou
                    que nous vous attribuons)
                  </h2>
                  <h2 className="CollectingYourInfoRightNav">
                    Données personnelles que nous traitons
                  </h2>
                </div>
                <hr />
                <div id="mainpersonaldatathatweprocess_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Pour assurer le bon fonctionnement de nos activités,
                        nous pouvons être amenés à collecter, utiliser et
                        stocker certaines de vos données personnelles. Ces
                        activités sont désignées collectivement par la
                        formulation «&nbsp;traiter vos données&nbsp;». Si vous
                        êtes un employé, nous traitons les données personnelles
                        qui vous concernent (et qui concernent aussi vos
                        subordonnés, prestataires et autres collaborateurs),
                        principalement pour gérer la relation de travail qui
                        nous lie, ainsi que la gestion de vos interactions avec
                        les installations et les systèmes d’information de votre
                        lieu de travail. Si vous êtes un ancien employé, nous
                        traitons essentiellement les données personnelles vous
                        concernant dans le cadre du respect de la législation.
                        Si vous faites partie du personnel externe, nous
                        traitons uniquement les données personnelles dont nous
                        avons besoin pour gérer votre collaboration avec
                        Microsoft et vos accès à ses installations et systèmes
                        d’information. Si vous postulez à l’une de nos offres
                        d’emploi, nous traitons uniquement les données
                        personnelles dont nous avons besoin pour vous informer
                        et échanger avec vous sur les opportunités de carrière
                        chez Microsoft, l’examen de votre candidature à des
                        postes spécifiques au sein de notre entreprise. Ces
                        données incluent la présélection des candidats, la
                        préparation et le déroulement des entretiens, les
                        vérifications des antécédents judiciaires et
                        l’intégration à Microsoft, en cas de réponse positive.
                      </p>
                      <p>
                        Les données personnelles que nous traitons peuvent
                        inclure, entre autres, les éléments suivants&nbsp;:
                      </p>
                      <p>
                        <strong>
                          Données relatives à votre identité et vos coordonnées
                        </strong>
                        . Votre nom, prénom, numéro d’identification d’employé,
                        adresse e-mail, adresse postale, numéro de téléphone,
                        photo, mais aussi les coordonnées de la personne à
                        prévenir s’il vous arrive un accident, ainsi que
                        d’autres données du même type. Vous pouvez également
                        fournir à Microsoft des coordonnées supplémentaires,
                        telles que votre adresse e-mail et numéro de téléphone
                        portable personnels.
                      </p>
                      <p>
                        <strong>Données démographiques</strong>. Votre date de
                        naissance et le sexe, ainsi que des données personnelles
                        plus sensibles (également appelées données de catégorie
                        spéciales), notamment des informations relatives à
                        l’origine raciale et ethnique, aux convictions
                        religieuses, politiques ou philosophiques, à
                        l’appartenance à un syndicat ou aux informations
                        concernant votre santé, vos handicaps, l’orientation
                        sexuelle, l’identité sexuelle et le statut de
                        transgenre. Nous pouvons également vous interroger sur
                        votre statut parental et militaire.
                      </p>
                      <p>
                        Nous procédons au traitement de ces données personnelles
                        pour diverses raisons. Celles-ci peuvent varier en
                        fonction de nos différentes juridictions. Les raisons du
                        traitement de ces données incluent&nbsp;:
                      </p>
                      <ol>
                        <li>
                          Nécessité de respecter la règlementation locale en
                          vigueur et le droit applicable. Nous pouvons, par
                          exemple, utiliser ces informations pour nous conformer
                          aux lois interdisant toute forme de discrimination,
                          ainsi qu’à l’obligation de signalement au
                          gouvernement&nbsp;:
                        </li>
                        <li>
                          Pour contrôler et garantir la diversité et l’égalité
                          de traitement et d’opportunité&nbsp;;
                        </li>
                        <li>
                          Pour fournir des aménagements ou ajustements liés au
                          travail, pour vous offrir des avantages en matière de
                          santé et d’assurances, et pour gérer les absences du
                          travail.
                        </li>
                      </ol>
                      <p>
                        Lorsque le traitement de ces données personnelles n’est
                        pas requis par la loi, nous vous demanderons votre
                        accord pour le traitement de vos données et, dans le
                        cadre du mécanisme de consentement, nous vous décrirons
                        les raisons pour lesquelles nous utiliserons vos
                        données. Ceci est volontaire. Vous êtes libre de donner
                        ou de ne pas donner votre consentement.
                      </p>
                      <p>
                        <strong>Identifiants nationaux</strong>. Votre carte
                        d’identité/passeport national(e), votre statut de
                        citoyen, votre statut de résident, votre statut de
                        permis de travail, votre numéro de sécurité sociale ou
                        tout autre numéro d’identification de
                        contribuable/public.
                      </p>
                      <p>
                        <strong>Détails de l’emploi</strong>. Votre titre/poste,
                        l'emplacement du bureau et/ou le lieu de travail à
                        distance, le contrat de travail, la lettre d'offre, la
                        date d'embauche, la date de cessation d'emploi,
                        l'historique des performances et les dossiers
                        disciplinaires, les dossiers de formation, les congés,
                        les congés de maladie et les dossiers de vacances/jours
                        fériés.
                      </p>
                      <p>
                        <strong>
                          Informations sur les conjoints(es)/partenaires et les
                          personnes à charge
                        </strong>
                        . Le prénom, le nom, la date de naissance et les
                        coordonnées de votre conjoint(e)/partenaire et vos
                        personnes à charge.
                      </p>
                      <p>
                        <strong>Informations sur votre parcours</strong>. Les
                        données relatives à vos diplômes universitaires et
                        qualifications professionnelles, votre formation, votre
                        curriculum vitæ, vos références de crédits et votre
                        casier judiciaire. Ces données sont strictement
                        utilisées pour vérifier les informations que vous nous
                        fournissez, et uniquement obtenues et traitées dans le
                        respect des lois en vigueur.
                      </p>
                      <p>
                        <strong>Vidéo, voix et image</strong>. Nous pouvons
                        recueillir et utiliser vos données de vidéo, de la voix
                        et de l’image, sous réserve des exigences de la
                        législation locale, de la politique interne et de toute
                        exigence de consultation avec les représentants des
                        travailleurs (le cas échéant).
                      </p>
                      <p>
                        <strong>Informations financières</strong>. Les détails
                        de votre compte bancaire, les informations fiscales, le
                        salaire, les informations sur les comptes de retraite,
                        les allocations de l’entreprise et d’autres informations
                        nécessaires pour administrer les paies, les taxes, les
                        avantages et l’équité et la rémunération des primes
                        incitatives.
                      </p>
                      <p>
                        <strong>
                          Données relatives aux formations et aux compétences
                        </strong>
                        . Comme décrit dans l’addenda concernant les données
                        relatives aux formations et aux compétences.
                      </p>
                      <p>
                        <strong>
                          Commentaires et données relatives aux sentiments
                        </strong>
                        . Vos réponses aux enquêtes relatives au bien-être des
                        employés telles que Employee Signals et Daily Pulse et
                        les commentaires recueillis concernant les responsables
                        et collègues via des outils tels que Manager Feedback et
                        Perspectives.
                      </p>
                      <p>
                        <strong>
                          Espace de travail, appareil, utilisation et données de
                          contenu
                        </strong>
                        . Données d'application (telles que les données
                        provenant d'Office 365, Teams, Outlook ou des processus
                        opérationnels internes), y compris les courriels envoyés
                        et reçus, les entrées de calendrier, les tâches à
                        effectuer, les messages instantanés, les données et
                        informations techniques (ne contenant que des
                        identifiants limités, le cas échéant des données à
                        caractère personnel) dans le cadre de l'utilisation des
                        applications (en ligne), de l'accès aux bâtiments et aux
                        systèmes d'information, des appareils Microsoft, de
                        l'utilisation des systèmes et des applications (y
                        compris la télémétrie) lors de l'accès et de
                        l'utilisation des bâtiments et des actifs de
                        l'entreprise Microsoft. Veuillez noter que vous pouvez
                        trouver d’autres informations, concernant les types de
                        données spécifiques que Microsoft peut utiliser à des
                        fins d’amélioration d’un produit, dans plusieurs
                        ressources, notamment l’addenda
                        Microsoft&nbsp;Data&nbsp;Program (MDP) à cette
                        Déclaration sur la protection des données personnelles.
                        Nous pouvons également collecter des données
                        personnelles de tiers ou de sources publiques
                        nécessaires à la prise en charge de la relation de
                        travail ou pour vous impliquer sur les opportunités de
                        travail de Microsoft. Par exemple, avant et pendant
                        votre travail ou votre mission avec Microsoft, nous
                        pouvons collecter les informations relatives aux sources
                        de réseau professionnel public, comme votre profil
                        LinkedIn, à des fins de recrutement. Nous pouvons aussi
                        être amenés à vous demander un extrait de votre casier
                        judiciaire et à contacter un prestataire pour vérifier
                        vos informations (formation, emplois précédents), dans
                        le respect du cadre prévu par la loi. En cas de
                        catastrophe naturelle ou d’autre urgence vitale ou de
                        sécurité, nous pouvons être amenés à consulter des
                        publications sur les réseaux sociaux ou d’autres sources
                        d’informations publiques pour nous enquérir de la
                        situation de nos employés, si nous sommes dans
                        l’impossibilité de les contacter. En outre, en cas
                        d’enquête sur un incident impliquant des employés, nous
                        pouvons obtenir des informations relatives à l’incident
                        auprès de sources externes, notamment des prestataires
                        indépendants, des services de police ou des sources
                        d’informations et des publications sur des réseaux
                        sociaux.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainwhyweprocesspersonaldata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainwhyweprocesspersonaldata_Header"
                  >
                    Pourquoi traitons-nous des données personnelles&nbsp;?
                  </h2>
                </div>
                <hr />
                <div id="mainwhyweprocesspersonaldata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Nous collectons vos données personnelles pour répondre
                        aux finalités indiquées ci-dessous. Le fait de ne pas
                        fournir vos données personnelles lorsque vous y êtes
                        invité peut empêcher la possibilité d’effectuer ces
                        tâches et/ou de respecter nos obligations légales.
                      </p>
                      <p>
                        1. Pour gérer votre contrat de travail, votre lettre
                        d'offre ou d'autres engagements que nous avons pris
                        envers vous.
                      </p>
                      <p>
                        Nous collectons et utilisons vos données personnelles
                        principalement pour gérer notre emploi ou notre relation
                        de travail avec vous, et pour remplir nos obligations en
                        vertu de votre contrat de travail ou des politiques
                        applicables de Microsoft, y compris l'intégration, la
                        paie, la gestion des avantages sociaux et de la
                        rémunération en actions, la gestion des pensions et des
                        retraites, la gestion des vacances et d'autres types de
                        congés, la déclaration d'impôts, etc. Voici quelques
                        exemples&nbsp;: votre contrat de travail, votre lettre
                        d’offre (pour nous permettre de vous intégrer, par
                        exemple), un historique des promotions et des
                        évaluations des performances (pour nous permettre de
                        gérer notre relation de travail), ainsi que votre compte
                        bancaire et le détail de votre salaire (pour nous
                        permettre de vous payer ou vous proposer des avantages
                        des RH).
                      </p>
                      <p>
                        2. Autres objectifs commerciaux prépondérants et
                        légitimes
                      </p>
                      <p>
                        Nous pouvons également collecter et utiliser vos données
                        à caractère personnel lorsque cela est nécessaire à
                        d'autres fins légitimes, telles que l'administration
                        générale des ressources humaines, la tenue de notre
                        annuaire mondial des employés et du personnel externe,
                        la gestion et les opérations commerciales générales, les
                        divulgations à des fins d'audit et de rapport,
                        l'évaluation du sentiment des employés, les enquêtes
                        internes, la gestion de la sécurité des réseaux et des
                        systèmes d'information, l'administration des
                        applications et des systèmes commerciaux, les opérations
                        commerciales, la sécurité, la sécurité des personnes, la
                        gestion des bâtiments, la planification et l'affectation
                        des espaces, la fourniture et l'amélioration des
                        services et installations destinés aux employés, la
                        sécurité physique et la cybersécurité, la protection des
                        données, les initiatives mondiales en matière de
                        diversité et d'inclusion, la protection de la vie et de
                        la sécurité des employés et d'autres personnes, ainsi
                        que dans le cadre de la vente, de la cession ou de tout
                        autre transfert de l'ensemble ou d'une partie de nos
                        activités. Nous utilisons également les données
                        professionnelles et d’autres utilisations liées aux
                        lieux de travail, aux appareils et aux données de
                        contenu pour des analyses individuelles et
                        d’organisation et à des fins d’informations sur les
                        données pour améliorer les opérations de l’entreprise
                        Microsoft, les capacités des responsables et
                        l’expérience des employés. Nous pouvons également
                        utiliser des applications et des systèmes spéciaux qui
                        enregistrent les mesures de performance des employés
                        (par exemple, une base de données répertoriant des
                        ventes), pour gérer les activités de notre entreprise,
                        ainsi que pour examiner, récompenser et encadrer les
                        employés selon leurs performances, mais aussi pour
                        administrer et évaluer de leurs formations. Nous pouvons
                        également traiter vos données personnelles pour
                        identifier les risques d’illégalités ou de violations de
                        nos politiques internes.
                      </p>
                      <p>
                        En outre, nous pouvons traiter vos données personnelles
                        pour mener des études scientifiques, sans aucun
                        consentement supplémentaire de votre part, lorsqu’elles
                        sont considérées comme ayant un intérêt public et/ou
                        lorsqu’il existe une tentative évidente de contribution
                        à des connaissances pouvant être généralisées. Dans ces
                        cas, nous veillerons à ce que des contrôles techniques
                        et organisationnels pertinents soient mis en place pour
                        protéger vos données personnelles, tels que
                        l’anonymisation et l’agrégation des données pour
                        protéger votre identité, en s’assurant que l’utilisation
                        de vos données personnelles est soumise à nos normes en
                        matière de confidentialité et en réalisant des
                        évaluations sur l’étique et la conformité avant
                        l’utilisation de vos données personnelles.
                      </p>
                      <p>
                        Étant donné que Microsoft active les expériences prises
                        en charge par l’IA dans ses produits, vos données
                        peuvent également être traitées par l’IA pour faciliter
                        certaines fonctionnalités et expériences déployées sur
                        le locataire – Microsoft, notamment les fonctionnalités
                        de co-pilote d’IA telles que les fonctionnalités de
                        chatbot, les fonctionnalités de synthèse, et ainsi de
                        suite. Le traitement de vos données par Microsoft sera
                        conforme à son engagement en faveur d'une IA
                        responsable.
                      </p>
                      <p>3. Finalités légales obligatoires</p>
                      <p>
                        Nous pouvons également utiliser vos données à caractère
                        personnel lorsque cela est nécessaire pour nous
                        conformer aux lois et réglementations, y compris la
                        collecte et la divulgation de données à caractère
                        personnel requises par la loi (par exemple, pour le
                        salaire minimum, le temps de travail, la fiscalité, la
                        santé et la sécurité, les lois anti-discrimination, la
                        migration mondiale, les procédures de dénonciation et
                        les droits des personnes concernées), en vertu d'une
                        autorisation judiciaire, ou pour exercer ou défendre les
                        droits légaux de Microsoft.
                      </p>
                      <p>
                        4. Autres utilisations de vos données (le cas échéant,
                        conformément aux exigences légales et de consultation
                        applicables)
                      </p>
                      <p>
                        Nous pouvons également collecter vos données
                        d’utilisation interne des produits, services,
                        applications et outils internes de Microsoft, y compris
                        les données professionnelles créées par les employés et
                        le personnel externe, pour mesurer et améliorer ces
                        produits et pour la recherche sur des produits. Cela
                        peut inclure leur examen par des humains et des machines
                        pour entraîner des modèles d’intelligence artificielle
                        et améliorer les fonctionnalités de l’apprentissage
                        automatique pour les produits et services de Microsoft.
                        En outre, vos données d’utilisation internes peuvent
                        être combinées avec d’autres données métier, notamment
                        l’espace de travail, l’appareil, l’utilisation et les
                        données de contenu, à des fins d’amélioration du produit
                        ou pour effectuer des analyses agrégées afin d’améliorer
                        les outils et processus internes, les opérations
                        commerciales, la capacité du responsable et l’expérience
                        des employés. Nous vous demanderons votre consentement
                        pour de telles utilisations dès lors que la loi l’exige.
                        Lors de ces demandes, nous nous assurerons que vous
                        acceptez en connaissance de cause, de votre plein gré,
                        et qu’un refus de votre part ou une révocation
                        ultérieure de votre consentement n’entraînera aucun
                        préjudice pour vous.
                      </p>
                      <p>
                        Pour les employés éligibles (c’est-à-dire les employés à
                        temps partiel ou à temps plein, les stagiaires, les
                        apprentis ou les chercheurs en visite) qui s’inscrivent
                        à Microsoft&nbsp;Give, avec votre consentement, nous
                        collectons et utilisons vos données personnelles pour
                        permettre des dons personnels volontaires d’argent, de
                        produits Microsoft ou d’heures de bénévolat à des
                        organisations éligibles (c’est-à-dire certains
                        organismes à but non lucratif ou organisations non
                        gouvernementales). Give est un programme d’avantages
                        sociaux volontaire dont les participants peuvent se
                        retirer et révoquer leur consentement à tout moment ;
                        toutefois, les retraits et révocations n’affectent pas
                        le traitement précédent des données personnelles. Des
                        informations supplémentaires sur Microsoft&nbsp;Give
                        sont disponibles{" "}
                        <a
                          href="https://microsoft.sharepoint.com/sites/employeegive"
                          target="_blank"
                        >
                          ici
                        </a>
                        .
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainchangeofpurpose">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainchangeofpurpose_Header"
                  >
                    Changement de finalité
                  </h2>
                </div>
                <hr />
                <div id="mainchangeofpurpose_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Nous utiliserons vos données personnelles uniquement aux
                        fins justifiant leur collecte, sauf si nous en avons
                        raisonnablement besoin pour une autre finalité
                        compatible et qu’une base juridique permet un traitement
                        ultérieur. Par exemple, en nous appuyant sur notre
                        intérêt légitime à recruter des candidats pour des
                        postes chez Microsoft, nous pouvons traiter les données
                        à caractère personnel que vous avez fournies en
                        recherchant des offres d'emploi. Toutefois, une fois que
                        vous postulez et vous réussissez à obtenir un rôle, vous
                        pouvons traiter vos données personnelles afin d’établir
                        des rapports de travail ensemble.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="page m-b-bl"
                id="mainhowandwhywesharepersonaldata"
              >
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainhowandwhywesharepersonaldata_Header"
                  >
                    Pourquoi partageons-nous des données personnelles&nbsp;? Et
                    comment&nbsp;?
                  </h2>
                </div>
                <hr />
                <div id="mainhowandwhywesharepersonaldata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Microsoft partagera uniquement vos données personnelles
                        avec les personnes qui en ont légitimement besoin.
                        Lorsque nous autoriserons un tiers à accéder à vos
                        données personnelles, nous nous assurerons que ces
                        données sont utilisées dans le respect des règles
                        établies dans la présente déclaration sur la protection
                        des données personnelles (mais aussi de nos règles de
                        gestion des données internes en vigueur, conformément au
                        niveau de confidentialité et de classification de ces
                        données). Nous pouvons être amenés à partager vos
                        données personnelles avec nos filiales, nos associés et
                        affiliés, ainsi que d’autres tiers (par exemple, des
                        prestataires de services) pour les finalités légitimes
                        suivantes&nbsp;:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Afin d’assurer notre traitement des données
                            personnelles tel que décrit ci-dessus (voir la
                            section intitulée&nbsp;: «&nbsp;Pourquoi
                            traitons-nous des données
                            personnelles&nbsp;?&nbsp;»).
                          </p>
                        </li>
                        <li>
                          <p>
                            Pour permettre aux tiers de fournir des services au
                            nom de Microsoft. Les destinataires tiers des
                            données peuvent inclure des prestataires de services
                            divers&nbsp;: financiers, assurance, administration
                            de pension, avantages sociaux, garde d’enfants,
                            gestion du registre du personnel, déménagement,
                            fiscalité, déplacement, santé, sécurité, gestion de
                            locaux et juridique.
                          </p>
                        </li>
                        <li>
                          <p>
                            Pour respecter nos obligations légales,
                            réglementations, autorisations gouvernementales ou
                            contrats, ou répondre aux droits de la personne
                            concernée, à un processus judiciaire ou
                            administratif, tel qu’une assignation à comparaître,
                            un audit public ou un mandat de perquisition. Les
                            catégories de destinataires incluent les
                            contreparties mentionnées dans nos contrats ou les
                            organismes juridictionnels et gouvernementaux.
                          </p>
                        </li>
                        <li>
                          <p>
                            Afin de répondre aux demandes licites des autorités
                            publiques (telles que les organes de réglementation,
                            les autorités de police et les organisations de
                            sécurité nationale).
                          </p>
                        </li>
                        <li>
                          <p>
                            Pour obtenir des conseils juridiques auprès
                            d’avocats externes, ainsi que des conseils d’autres
                            professionnels tels que des comptables,
                            gestionnaires, etc.
                          </p>
                        </li>
                        <li>
                          <p>
                            Si nécessaire, pour établir, exercer ou se défendre
                            contre un litige, contentieux ou procès potentiel,
                            une menace de procès ou un procès en cours.
                          </p>
                        </li>
                        <li>
                          <p>
                            Si nécessaire, pour assurer la sauvegarde d’intérêts
                            vitaux de Microsoft ou de vos intérêts vitaux, tels
                            que la sûreté et la sécurité, ou les intérêts vitaux
                            d’une autre personne.
                          </p>
                        </li>
                        <li>
                          <p>
                            Pour effectuer une vente, une cession ou un
                            transfert de la totalité ou d’une partie de nos
                            activités (auquel cas, les personnes concernées
                            peuvent être, par exemple, un acquéreur ou un
                            conseiller juridique/professionnel).
                          </p>
                        </li>
                        <li>
                          <p>
                            Pour d’autres raisons, conformément à votre
                            consentement.
                          </p>
                        </li>
                      </ol>
                      <p>
                        Veuillez noter que, si des exigences légales limitent le
                        partage de vos données personnelles, Microsoft respecte
                        ces exigences.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="page m-b-bl"
                id="mainyourrightstoyourpersonalinformation"
              >
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainyourrightstoyourpersonalinformation_Header"
                  >
                    Vos droits sur vos données personnelles
                  </h2>
                </div>
                <hr />
                <div id="mainyourrightstoyourpersonalinformation_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Dans certaines régions, vous pouvez disposer de certains
                        droits en vertu des lois applicables sur la protection
                        des données (par exemple, l’Union européenne et le
                        Royaume-Uni Règlement général sur la protection des
                        données et la Loi fédérale suisse sur la protection des
                        données). Veillez consultez l’
                        <a href="#mainmicrosoftdataprogram">Addendum</a> à cet
                        Avis pour obtenir des informations supplémentaires par
                        pays/région.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainuseofcookiesandwebbeacons">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainuseofcookiesandwebbeacons_Header"
                  >
                    Utilisation de cookies et de balises web
                  </h2>
                </div>
                <hr />
                <div id="mainuseofcookiesandwebbeacons_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Les pages de site peuvent utiliser des cookies (petits
                        fichiers texte placés sur votre appareil). Les cookies
                        et technologies similaires nous permettent, entre
                        autres, de stocker et d’honorer vos préférences et vos
                        paramètres ; de vous permettre de vous connecter&nbsp;;
                        de lutter contre la fraude&nbsp;; et d’analyser les
                        performances de nos sites web et services en ligne.
                      </p>
                      <p>
                        Nous utilisons également des «&nbsp;balises web&nbsp;»
                        pour nous aider à placer les cookies et à rassembler des
                        données d’utilisation et de performance. Nos sites web
                        peuvent inclure des balises web et des cookies, ou des
                        technologies similaires de prestataires de services
                        tiers.
                      </p>
                      <p>
                        Vous disposez d’une variété d’outils pour contrôler les
                        cookies, les balises web et les technologies similaires.
                        Par exemple, vous pouvez utiliser les contrôles de votre
                        navigateur Internet pour limiter l'utilisation des
                        cookies par les sites Web que vous visitez et retirer
                        votre consentement en supprimant ou en bloquant les
                        cookies.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="page m-b-bl"
                id="mainworkplacesecurityandmonitoring"
              >
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainworkplacesecurityandmonitoring_Header"
                  >
                    Sécurité et surveillance de l’espace de travail
                  </h2>
                </div>
                <hr />
                <div id="mainworkplacesecurityandmonitoring_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Microsoft surveille ses systèmes informatiques et de
                        communication au moyen d'outils automatisés tels que des
                        logiciels et du matériel d'authentification de réseau et
                        de connectivité sans fil, des logiciels anti-malware,
                        des logiciels de filtrage de sites web et de spam, des
                        logiciels de sécurité pour les applications basées sur
                        le cloud, l'enregistrement des accès et des
                        transactions, des solutions de gestion des appareils
                        mobiles, ainsi que des audits internes et externes.
                        L’objectif principal de cette surveillance est l’intérêt
                        légitime de Microsoft de protéger ses employés, ses
                        clients et ses partenaires professionnels. Par
                        exemple&nbsp;:
                      </p>
                      <ol>
                        <li>
                          la sécurité de ses systèmes, applications et réseaux,
                          particulièrement la sécurité des systèmes
                          informatiques et des ressources de Microsoft, ainsi
                          que la sécurité de ses employés, de son personnel
                          externe et des tiers&nbsp;;
                        </li>
                        <li>
                          la gestion et la prise en charge adaptée de ses
                          réseaux et appareils&nbsp;;
                        </li>
                        <li>
                          la validation de ses transactions commerciales et leur
                          archivage&nbsp;;
                        </li>
                        <li>
                          la protection des informations confidentielles et des
                          ressources de l’entreprise&nbsp;;
                        </li>
                        <li>
                          les investigations en cas de suspicion d’actes
                          illicites ou de violations potentielles de la
                          politique de l’entreprise&nbsp;;
                        </li>
                        <li>
                          et toute autre finalité légitime de l’entreprise,
                          conformément aux réglementations en vigueur.
                        </li>
                      </ol>
                      <p>
                        Nous analysons également nos bureaux et d’autres lieux
                        de travail, par le biais de la surveillance vidéo, comme
                        la télévision en circuit fermé (“CCSP”) et les analyses
                        de badges pour la sécurité, la sécurité de la vie, les
                        tendances d’utilisation des campus et l’analyse du temps
                        de travail, la conformité des stratégies de l’espace de
                        travail de l’entreprise et la création d’objectifs de
                        gestion. Nous utilisons principalement la
                        vidéosurveillance pour surveiller les points d’entrée et
                        de sortie des bureaux, les halls d’ascenseur, les pièces
                        où se trouvent du matériel onéreux, comme les salles de
                        serveurs, ainsi que d’autres zones sélectionnées, car
                        présentant un risque élevé de vol ou abritant des
                        ressources essentielles. Nous n’utilisons pas la
                        vidéosurveillance dans les espaces privés tels que les
                        sanitaires, les pièces où travaillent des femmes ayant
                        récemment accouché ou les vestiaires. Nous n’utilisons
                        pas la vidéosurveillance pour surveiller la productivité
                        des employés.
                      </p>
                      <p>
                        Vous devez savoir que chaque message, fichier, donnée,
                        document, télécopie, appel audio et/ou vidéo,
                        publication ou message privé sur un réseau social, ou
                        tout autre type d’information créée, transmise, reçue,
                        stockée, enregistrée ou imprimée via des systèmes et
                        ressources informatiques et de communication de
                        Microsoft (y compris via l’utilisation d’appareils
                        personnels accédant aux systèmes informatiques de
                        Microsoft) est considéré(e) comme lié(e) aux activités
                        de Microsoft. Nous pouvons donc surveiller et consulter
                        son contenu, conformément aux lois et aux accords en
                        vigueur régissant les lieux de travail (tels que les
                        accords sur les comités d’entreprise), et conformément
                        aux politiques de Microsoft sur l’accès à ces données,
                        ainsi que leur utilisation.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainsecurityofyourpersonaldata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainsecurityofyourpersonaldata_Header"
                  >
                    Sécurité de vos données personnelles
                  </h2>
                </div>
                <hr />
                <div id="mainsecurityofyourpersonaldata_ShortDescription">
                  <label className="Description">
                    <span>
                      Microsoft s’engage à protéger vos données personnelles.
                      Nous utilisons plusieurs technologies et procédures de
                      sécurité pour protéger vos données personnelles contre
                      tout accès, utilisation ou divulgation non autorisés. Par
                      exemple, nous stockons les données personnelles que vous
                      fournissez sur des serveurs informatiques à accès limité
                      qui se situent dans des installations contrôlées, et nous
                      protégeons certaines données personnelles hautement
                      confidentielles ou sensibles via le chiffrement lors du
                      transfert et au repos.
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="page m-b-bl"
                id="mainwherewestoreandprocesspersonaldata"
              >
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainwherewestoreandprocesspersonaldata_Header"
                  >
                    Pays de stockage et de traitement des données personnelles
                  </h2>
                </div>
                <hr />
                <div id="mainwherewestoreandprocesspersonaldata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Microsoft mène des activités partout dans le monde. Par
                        conséquent, les données personnelles que nous traitons
                        peuvent être transférées vers d’autres pays que ceux où
                        les données personnelles ont été collectées. Par
                        exemple, étant donné que notre siège est situé aux
                        États-Unis, les données personnelles collectées dans
                        d’autres pays sont régulièrement transférées aux
                        États-Unis pour y être traitées. Nous transférons des
                        données personnelles de l'Espace économique européen, du
                        Royaume-Uni et de la Suisse vers d'autres pays, dont
                        certains n'ont pas encore été jugés par la Commission
                        européenne et/ou le Conseil fédéral suisse comme
                        présentant un niveau adéquat de protection des données.
                        Par exemple, leurs lois peuvent ne pas vous garantir les
                        mêmes droits, ou il est possible qu’il n’y ait pas
                        d'autorité de contrôle en matière de protection des
                        données personnelles capable de traiter vos plaintes.
                        Lorsque nous nous impliquons dans de tels transferts,
                        nous utilisons une variété de mécanismes juridiques,
                        notamment des contrats tels que les clauses
                        contractuelles standard publiées par la Commission
                        Européenne sous la Décision d’application de la
                        Commission 2021/914 pour contribuer à la protection de
                        vos droits et permettre à ces protections de circuler
                        avec vos données. Pour en savoir plus sur les décisions
                        de la Commission Européenne concernant l’adéquation de
                        la protection des données personnelles dans les pays où
                        Microsoft traite les données personnelles, consultez cet
                        article sur{" "}
                        <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en">
                          le site web de la Commission européenne
                        </a>
                        . Nous pouvons également transférer des données
                        personnelles lorsque (i) vous avez accepté la
                        divulgation à l’étranger ; (ii) il est nécessaire pour
                        la conclusion ou les performances d’un contrat ; (iii)
                        il est nécessaire de protéger un intérêt public
                        substitué ou d’établir, d’exercer ou d’appliquer des
                        droits juridiques ; (iv) il est nécessaire de protéger
                        la vie ou l’intégrité physique de vous ou d’une autre
                        personne, et il n’est pas possible d’obtenir votre
                        consentement dans un délai raisonnable ; (v) vous avez
                        rendu les données généralement accessibles et n’avez pas
                        explicitement interdit le traitement ; ou (vi) les
                        données proviennent d’un registre statutaire auquel nous
                        avons un accès légitime.
                      </p>
                      <p>
                        Microsoft Corporation respecte le cadre de protection
                        des données UE-États-Unis (EU-U.S. DPF), l'extension
                        britannique du cadre de protection des données
                        UE-États-Unis et le cadre de protection des données
                        Suisse-États-Unis (Swiss-U.S. DPF), tels qu'ils sont
                        définis par le ministère du commerce des États-Unis.
                        Microsoft Corporation a certifié au ministère américain
                        du commerce qu'elle adhère aux principes du cadre
                        UE-États-Unis de protection des données à caractère
                        personnel (principes du DPF UE-États-Unis) en ce qui
                        concerne le traitement des données à caractère personnel
                        reçues de l'Union européenne en vertu du DPF
                        UE-États-Unis et du Royaume-Uni (et de Gibraltar) en
                        vertu de l'extension britannique du DPF UE-États-Unis.
                        Microsoft Corporation a certifié au ministère américain
                        du commerce qu'elle adhère aux principes du cadre
                        suisse-américain de protection des données (principes du
                        DPF suisse-américain) en ce qui concerne le traitement
                        des données à caractère personnel reçues de la Suisse en
                        vertu du DPF suisse-américain. Dans le cadre d’un
                        transfert ultérieur, Microsoft Corporation est
                        responsable du traitement des données personnelles qu’il
                        reçoit dans le cadre du DPF, puis transfère à un tiers
                        agissant en tant qu’agent en notre nom. Microsoft
                        Corporation reste responsable en vertu du DPF si notre
                        agent traite ces informations personnelles d’une manière
                        incohérente avec le DPF, sauf si Microsoft Corporation
                        pouvez prouver que nous ne sommes pas responsables de
                        l’événement à l’origine des dommages. En cas de conflit
                        entre les termes de la présente déclaration de
                        confidentialité et les principes du DPF UE-États-Unis
                        et/ou les principes du DPF Suisse-États-Unis, ce sont
                        les principes qui prévalent. Pour en savoir plus sur le
                        programme Data Privacy Framework (DPF) et pour afficher
                        notre certification,{" "}
                        <a href="https://www.dataprivacyframework.gov/">
                          visitez le site web de l’Infrastructure de
                          confidentialité des données du département du Commerce
                          des États-Unis
                        </a>
                        . Les filiales américaines contrôlées de Microsoft
                        Corporation, telles qu'identifiées dans notre demande
                        d'autocertification, adhèrent également aux principes du
                        DPF - pour plus d'informations, voir la liste des{" "}
                        <a href="https://privacy.microsoft.com/entity-list-adhering-to-privacy-shield">
                          entités ou filiales américaines de Microsoft qui
                          adhèrent aux principes du DPF
                        </a>
                        .
                      </p>
                      <p>
                        Si vous avez une question ou une plainte concernant la
                        participation de Microsoft aux frameworks DPF, nous vous
                        encourageons à nous contacter via notre{" "}
                        <a href="https://go.microsoft.com/fwlink/p/?linkid=2126612">
                          formulaire web
                        </a>
                        . Pour toute plainte relative aux cadres DPF que
                        Microsoft ne peut résoudre directement, nous avons
                        choisi de coopérer avec l'autorité de protection des
                        données de l'UE concernée, ou avec un panel établi par
                        les autorités européennes de protection des données,
                        pour résoudre les litiges avec les particuliers de l'UE,
                        avec le commissaire à l'information du Royaume-Uni (pour
                        les particuliers du Royaume-Uni) et avec le commissaire
                        fédéral suisse à la protection des données et à
                        l'information (PFPDT) pour résoudre les litiges avec les
                        particuliers de la Suisse. Contactez-nous si vous
                        souhaitez que nous vous dirigions vers les points de
                        contact de votre autorité de protection des données.
                        Comme l'expliquent plus en détail les principes du DPF,
                        l'arbitrage obligatoire est possible pour traiter les
                        plaintes résiduelles qui n'ont pas été résolues par
                        d'autres moyens. La société Microsoft est soumise aux
                        pouvoirs d’enquête et de sanction de la Federal Trade
                        Commission&nbsp;(FTC) des États-Unis.
                      </p>
                      <p>
                        Les personnes dont les données personnelles sont
                        protégées par la loi japonaise sur la protection des
                        informations personnelles doivent se référer à l’article
                        sur le{" "}
                        <a href="https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/#gaikoku">
                          site web de la Commission Information Protection
                          personnelle japonaise
                        </a>{" "}
                        (uniquement publié en japonais) pour plus d’informations
                        sur l’examen par la Commission de certains systèmes de
                        protection des données personnelles de certains pays .
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainourretentionofpersonaldata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainourretentionofpersonaldata_Header"
                  >
                    Conservation des données personnelles
                  </h2>
                </div>
                <hr />
                <div id="mainourretentionofpersonaldata_ShortDescription">
                  <label className="Description">
                    <span>
                      Nous stockons les données personnelles conformément aux
                      lois et réglementations en vigueur. Nous les conservons
                      uniquement pendant la durée jugée nécessaire pour mener à
                      bien les objectifs justifiant leur collecte, tel
                      qu’indiqué dans notre planification organisationnelle de
                      la rétention des données.
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainchangestothisprivacynotice">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainchangestothisprivacynotice_Header"
                  >
                    Modification du présent Avis sur la protection des données
                    personnelles
                  </h2>
                </div>
                <hr />
                <div id="mainchangestothisprivacynotice_ShortDescription">
                  <label className="Description">
                    <span>
                      Nous pouvons occasionnellement actualiser cet Avis sur la
                      protection des données personnelles. Si nous le faisons,
                      nous modifierons la date de la «&nbsp;dernière mise à
                      jour&nbsp;» figurant en haut de la déclaration sur la
                      protection des données personnelles. En cas de
                      modification conséquente de cet avis sur la protection des
                      données personnelle ou de la façon dont Microsoft utilise
                      vos données personnelles, nous nous efforcerons de vous en
                      informer. Pour ce faire, nous publierons de façon visible
                      sur nos sites web une notification indiquant une telle
                      modification ou nous vous contacterons directement. Nous
                      vous recommandons fortement de consulter régulièrement le
                      présent avis sur la confidentialité afin de prendre
                      connaissance des modalités de protection de vos données
                      personnelles prévues par Microsoft.
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainhowtocontactus">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainhowtocontactus_Header"
                  >
                    Comment nous contacter
                  </h2>
                </div>
                <hr />
                <div id="mainhowtocontactus_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>
                        Pour obtenir des copies des addenda et documents
                        complémentaires mentionnés dans la présente déclaration
                        sur la protection des données personnelles, ou si vous
                        rencontrez un problème de protection de données
                        personnelles ou souhaitez obtenir plus d’informations
                        sur cet avis sur la confidentialité, veuillez contacter{" "}
                        <a href="mailto:AskHR@microsoft.com">
                          AskHR@microsoft.com
                        </a>
                        .
                      </p>
                      <p>Notre adresse est la suivante&nbsp;:</p>
                      <p>HR Privacy</p>
                      <p>Microsoft&nbsp;Corporation</p>
                      <p>One Microsoft Way</p>
                      <p>Redmond, Washington 98052 USA (États-Unis)</p>
                      <p>Téléphone&nbsp;: (+1) 425-882-8080.</p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainepp">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2 className="CollectingYourInfo" id="mainepp_Header">
                    Principes de confidentialité des employés de Microsoft
                  </h2>
                </div>
                <hr />
                <div id="mainepp_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour : Janvier 2023</p>
                      <p>
                        Microsoft estime que la protection de la vie privée est
                        un droit humain fondamental. Il est essentiel pour notre
                        activité que les consommateurs et les entreprises nous
                        confient leurs données. Avec la confiance, nous pouvons
                        donner à chaque individu et à chaque organisation de la
                        planète les moyens d’en faire plus.
                      </p>
                      <p>
                        De même, le respect de ces principes sur le lieu de
                        travail permet à nos employés de donner le meilleur
                        d'eux-mêmes. Nos employés sont le moteur de notre
                        mission chaque jour. Leur confiance est essentielle pour
                        que nous puissions mener à bien cette mission. Nous
                        sommes convaincus que les employés ne renoncent pas
                        fondamentalement à leurs droits en matière de protection
                        de la vie privée du fait de leur emploi chez Microsoft.
                        Nous respectons les lois et les exigences en matière de
                        protection de la vie privée de tous les pays où nous
                        exerçons nos activités. Dans de nombreux cas, Microsoft
                        va au-delà de ce qui est requis pour s'assurer que nos
                        employés peuvent vraiment avoir confiance dans le fait
                        que Microsoft agira de manière responsable avec les
                        données que nous recueillons à leur sujet et qu'ils
                        resteront les plus grands champions et défenseurs de
                        notre entreprise.
                      </p>
                      <p>
                        En bref,{" "}
                        <strong>
                          Microsoft adopte une approche réfléchie et délibérée
                          de la protection de la vie privée
                        </strong>{" "}
                        des employés, qui reconnaît le caractère unique de la
                        relation de travail tout en conciliant les intérêts de
                        la société à mener une activité sûre, inclusive,
                        efficace et innovante. Notre approche est soutenue par
                        un programme de protection de la vie privée qui se
                        préoccupe profondément de ces questions, comme en
                        témoigne l'investissement de Microsoft dans son Conseil
                        de gouvernance des données des employés (EDGB), qui
                        supervise les exigences des normes de protection de la
                        vie privée de Microsoft concernant les données des
                        employés, et les partenaires du programme de protection
                        de la vie privée établi par Microsoft pour s'assurer que
                        les équipes traitent la protection de la vie privée des
                        employés avec un soin extraordinaire.
                      </p>
                      <p>
                        La relation de travail est différente d'une relation
                        avec un consommateur ou un client, et signifiera parfois
                        que Microsoft a des obligations contractuelles, légales
                        ou autres d'utiliser les données des employés, notamment
                        pour fournir des rapports gouvernementaux obligatoires
                        (tels que les rapports exigés de Microsoft en tant que
                        contractant fédéral, ou les rapports sur les écarts de
                        rémunération dans certains pays), ou pour prendre les
                        mesures appropriées pour défendre ou poursuivre les
                        réclamations juridiques faites contre ou par la société.
                      </p>
                      <p>
                        Soucieuse de préserver la confiance et d'équilibrer la
                        nature différente de la relation de travail, Microsoft a
                        adopté six principes fondamentaux en matière de
                        protection de la vie privée des employés :
                      </p>
                      <p>
                        <strong>
                          I. Microsoft fournit des informations sur la manière
                          dont les données des employés sont utilisées.
                        </strong>
                      </p>
                      <p>
                        Microsoft estime avant tout que les employés doivent
                        être informés de manière claire et appropriée de la
                        manière dont les données les concernant peuvent être
                        utilisées. Cet avis commence par l'avis de{" "}
                        <a
                          href="https://privacy.microsoft.com/data-privacy-notice"
                          title="la déclaration de confidentialité"
                          target="_blank"
                        >
                          confidentialité des données de Microsoft pour les
                          employés, le personnel externe, les candidats et les
                          invités{" "}
                        </a>
                        (DPN). Le DPN et ses addenda définissent le cadre de
                        l'ensemble du traitement des données des employés par
                        Microsoft. Si vous n'avez pas encore eu l'occasion de
                        consulter le DPN, nous vous encourageons à le faire. Le
                        DPN et ses addenda sont mis à jour chaque année, et les
                        employés se voient rappeler le DPN chaque année dans le
                        cadre de la formation obligatoire sur la protection de
                        la vie privée.
                      </p>
                      <p>
                        Outre le DPN, Microsoft fournira des informations plus
                        spécifiques sur la protection de la vie privée lorsque
                        cela s'avérera nécessaire. Par exemple, notre programme
                        Elite dogfooding fournit fréquemment des informations
                        supplémentaires sur les types de données collectées lors
                        de l'évaluation de nouveaux produits. En outre, votre
                        contrat de travail local ou votre convention collective
                        peut également contenir des dispositions relatives au
                        traitement des données.
                      </p>
                      <p>
                        <strong>
                          II. Dans la mesure du possible, Microsoft offre la
                          possibilité de choisir la manière dont les données des
                          employés sont utilisées.
                        </strong>
                      </p>
                      <p>
                        Bien que Microsoft ne s'appuie pas sur le consentement
                        pour le traitement de la plupart des données relatives
                        aux employés (sauf obligation légale), nous pensons
                        qu'il faut offrir aux employés la possibilité de choisir
                        la manière dont ces données sont traitées, le cas
                        échéant. Ce choix peut prendre de nombreuses formes.
                        Dans certains cas, il s'agit d'offrir aux employés la
                        possibilité de refuser certains types de
                        caractéristiques de produits ou certaines utilisations
                        vraiment facultatives des données. Le programme de
                        données Microsoft (MDP) est un bon exemple de ce type de
                        choix. Pour en savoir plus sur ce programme,{" "}
                        <a href="#mainMDPaddendum_Header">
                          consultez l'addendum du PDM au DPN
                        </a>
                        . Ce programme exploite généralement les données
                        commerciales approuvées de Microsoft pour le
                        développement et l'amélioration des produits, sous
                        réserve d'un certain nombre de contrôles et de
                        limitations. Les employés des pays où le programme est
                        actif ont la possibilité de choisir de ne pas participer
                        entièrement au programme ou de prendre des mesures pour
                        limiter les types de données traitées par ce programme.
                      </p>
                      <p>
                        La nature unique de la relation d’emploi signifie que le
                        choix peut être plus limité ou non disponible pour
                        certains types de traitement des données (traitement des
                        paies, par exemple). De même, lorsque Microsoft dispose
                        de droits ou d’obligations juridiques ou contractuels
                        pour traiter ou divulguer des données, nous ne pouvons
                        pas autoriser le choix de la façon dont ces données sont
                        utilisées.
                      </p>
                      <p>
                        <strong>
                          III. Microsoft équilibre judicieusement les intérêts
                          des employés et de l'entreprise lors de l'utilisation
                          des données..
                        </strong>
                      </p>
                      <p>
                        Lorsque le traitement des données relatives aux employés
                        n'est pas entièrement étayé par des exigences légales,
                        contractuelles ou autres, Microsoft examine
                        attentivement ses intérêts à utiliser les données et les
                        met en balance avec les intérêts de l'employé en matière
                        de protection de la vie privée à l'égard des données. En
                        particulier, lorsqu'il s'agit d'utiliser des données
                        commerciales pour certains types d'utilisations
                        facultatives ou «secondaires», comme le développement de
                        produits ou la connaissance du marché, Microsoft examine
                        attentivement l'impact qu'une telle utilisation peut
                        avoir sur la vie privée des employés, et les contrôles
                        qu'elle peut et doit mettre en place pour protéger la
                        vie privée des employés avant de procéder à cette
                        utilisation. Microsoft peut, par exemple, offrir la
                        possibilité de refuser certaines utilisations des
                        données, veiller à ce que les données soient
                        dépersonnalisées, pseudonymisées ou anonymisées avant
                        leur utilisation, ou mettre en œuvre d'autres types de
                        mesures et de contrôles de sécurité pour garantir une
                        utilisation appropriée des données.
                      </p>
                      <p>
                        Un bon exemple en est la conception et la mise en œuvre
                        de Viva Insights, qui exploite les données pour vous
                        fournir directement des informations qui vous aideront à
                        prendre des décisions sur la manière dont vous
                        investissez votre temps au travail. Ces informations ne
                        sont pas communiquées à votre supérieur hiérarchique au
                        niveau individuel. C'est délibérément que nous voulons
                        que ces informations restent au niveau de l'équipe ou du
                        groupe, conformément à notre engagement en faveur de la
                        protection de la vie privée des employés.
                      </p>
                      <p>
                        <strong>
                          iv. L'utilisation des données relatives aux employés
                          est limitée et contrôlée de manière appropriée.
                        </strong>
                      </p>
                      <p>
                        Lorsque Microsoft utilise des données, elle prend des
                        mesures raisonnables pour s'assurer qu'elle n'utilise
                        que les données nécessaires à une utilisation
                        particulière. Par exemple, nous demandons aux équipes
                        qui souhaitent utiliser des données pour le
                        développement de produits ou l'expérimentation d'adapter
                        leurs besoins en données à ceux qui sont strictement
                        nécessaires à leur travail. Les équipes qui souhaitent
                        utiliser nos données doivent se conformer aux exigences
                        existantes en matière de protection de la vie privée ou
                        s'engager dans des processus rigoureux qui examinent
                        l'accès aux données des employés et leur utilisation
                        afin de garantir une minimisation et une portée
                        appropriées de l'utilisation. L'accès aux données qui ne
                        sont pas nécessaires pour soutenir le champ
                        d'application prévu est généralement interdit.
                      </p>
                      <p>
                        <strong>
                          V. Microsoft donne accès aux données des employés.
                        </strong>
                      </p>
                      <p>
                        Microsoft permet régulièrement à ses employés d'accéder
                        à leurs propres données, telles que leur salaire, leurs
                        avantages sociaux, leurs congés, leurs récompenses et
                        leurs connexions, par le biais de portails en
                        libre-service. Microsoft offre également aux employés un
                        accès supplémentaire à leurs données individuelles à la
                        demande de l'employé, dans la mesure où la législation
                        locale l'exige. En donnant aux employés un accès en
                        libre-service à ces données et la possibilité d'y
                        apporter des corrections et des mises à jour le cas
                        échéant, on s'assure que les employés ont toujours accès
                        aux données qui leur tiennent le plus à cœur.
                      </p>
                      <p>
                        <strong>
                          Vi. Les données des employés sont protégées par des
                          mesures de sécurité de premier plan.
                        </strong>
                      </p>
                      <p>
                        Outre la protection de la vie privée, la sécurité des
                        données relatives à nos employés est primordiale. Les
                        données relatives à nos employés sont soigneusement
                        contrôlées. Nous limitons l'accès aux données les plus
                        sensibles, comme celles utilisées par nos équipes de
                        ressources humaines, à ceux qui en ont réellement besoin
                        pour des raisons professionnelles et nous exigeons des
                        équipes qu'elles respectent les exigences existantes en
                        matière de protection de la vie privée ou qu'elles
                        procèdent à un examen de la protection de la vie privée
                        pour les nouvelles utilisations de données afin de
                        s'assurer qu'elles sont appropriées. Les données de nos
                        employés sont également considérées comme des « données
                        clients » par nos équipes d'ingénierie, nécessitant un
                        examen, une approbation et des contrôles appropriés
                        avant que Microsoft n'autorise l'utilisation de ces
                        données.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainCanadadata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2 className="CollectingYourInfo" id="mainCanadadata_Header">
                    Addendum californien.
                  </h2>
                </div>
                <hr />
                <div id="mainCanadadata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour : décembre 2022</p>
                      <p>
                        <strong>Californie : vos droits</strong>
                      </p>
                      <p>
                        Si vous êtes un employé, un membre du personnel externe
                        ou un candidat résidant en Californie, cette section
                        s’applique à vous et complète les informations partagées
                        dans l’avis de confidentialité.{" "}
                      </p>
                      <p>
                        Les résidents californiens disposent de droits
                        spécifiques concernant leurs informations personnelles
                        en vertu de la Loi californienne sur les droits relatifs
                        à la confidentialité (« CPR »). Cette section décrit vos
                        droits et explique comment exercer ces droits. Notez
                        qu’au cours des douze (12) mois précédents, nous n’avons
                        pas vendu vos informations personnelles ou partagé ces
                        informations pour la publicité comportementale entre
                        contextes. Nous pouvons divulguer certaines informations
                        personnelles, telles que votre prénom et votre nom,
                        votre numéro d’identification d’employé, votre adresse
                        e-mail, les détails du compte bancaire, le poste/emploi
                        et d’autres données de contact similaires, des
                        informations financières et des informations sur
                        l’emploi avec nos filiales et affiliés et d’autres
                        tiers, y compris les fournisseurs de services qui
                        fournissent des services pour le compte de Microsoft.{" "}
                      </p>
                      <ol>
                        <li>
                          Vous pouvez demander un avis et accéder à certaines
                          informations à propos de notre collecte et
                          l’utilisation de vos informations personnelles au
                          cours des 12 derniers mois. Une fois que nous avons
                          reçu et confirmé votre requête vérifiable, nous
                          pouvons vous divulguer :{" "}
                          <ul>
                            <li>
                              Les catégories sources d’informations personnelles
                              que nous avons recueillies à votre sujet.
                            </li>
                            <li>
                              Our objectif commercial ou professionnels pour la
                              collecte de ces informations personnelles
                            </li>
                            <li>
                              Les catégories de tiers avec lesquels nous avons
                              divulgué ces informations personnelles.{" "}
                            </li>
                            <li>
                              Les éléments spécifiques des informations
                              personnelles
                            </li>
                            <li>
                              {" "}
                              spécifiques que nous avons collectés à votre sujet
                              (également appelé demande de portabilité des
                              données).
                            </li>
                            <li>
                              Si nous avons divulgué vos informations
                              personnelles à des fins commerciales, une liste de
                              divulgations identifiant les catégories
                              d’informations personnelles obtenues par chaque
                              catégorie de destinataire.{" "}
                            </li>
                          </ul>
                        </li>
                        <li>
                          Vous pouvez demander que nous corrigions les
                          informations personnelles qui vous concernant sont
                          inexactes.
                        </li>
                        <li>
                          Vous pouvez demander que nous supprimions vos
                          informations personnelles que nous avons collectées
                          auprès de vous et conservées, sous réserve de
                          certaines exceptions. Une fois que nous aurons reçu et
                          confirmé votre requête vérifiable, nous supprimerons
                          ou dé-identifierons (et demanderons à nos fournisseurs
                          de services de supprimer ou d’identifier) vos
                          informations personnelles de nos enregistrements, sauf
                          si une exception s’applique.
                        </li>
                      </ol>
                      <p>
                        Aucun de ces droits n’est absolu et il peut y avoir des
                        circonstances dans lesquelles nous sommes tenus ou
                        autorisés, en vertu de la loi applicable, de ne pas
                        traiter votre requête.{" "}
                      </p>
                      <p>
                        Seuls vous ou un agent autorisé que vous autorisez à
                        agir en votre nom peuvent effectuer une requête
                        vérifiable liée à vos informations personnelles.{" "}
                      </p>
                      <p>
                        Toute demande vérifiable (y compris celles permettant de
                        supprimer des données) doit :
                      </p>
                      <ul>
                        <li>
                          Fournir des informations suffisantes qui nous
                          permettent de vérifier raisonnablement que vous êtes
                          la personne à propos de laquelle nous avons collecté
                          des informations personnelles ou un représentant
                          autorisé (par exemple, en vous demandant de fournir
                          une autorisation écrite signée que l’agent est
                          autorisé à effectuer une demande en votre nom).{" "}
                        </li>
                        <li>
                          Décrivez votre demande avec suffisamment de détails
                          pour nous permettre de la comprendre, de l’évaluer et
                          de y répondre correctement.
                        </li>
                      </ul>
                      <p>
                        Nous ne pouvons pas répondre à votre requête ou vous
                        fournir des informations personnelles si nous ne pouvons
                        pas vérifier votre identité ou votre autorité pour
                        effectuer la requête et confirmer les informations
                        personnelles vous concernant. L’exécution d’une demande
                        vérifiable ne vous oblige pas à créer un compte avec
                        nous.{" "}
                      </p>
                      <p>
                        Nous ne vous pénaliserons pas pour exercer vos droits
                        lorsque la loi l’interdit.
                      </p>
                      <p>
                        Vous pouvez exercer vos droits dans le cadre de la CARCH
                        par le biais de l’un des moyens suivants :
                      </p>
                      <ul>
                        <li>
                          Envoi d’une requête à{" "}
                          <a
                            target="_blank"
                            className="mscom-link"
                            href="mailto:AskHR@microsoft.com"
                          >
                            AskHR@microsoft.com
                          </a>
                        </li>
                        <li>Nous appeler au (+1) 425-882-8080 </li>
                      </ul>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainmicrosoftdataprogram">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainmicrosoftdataprogram_Header"
                  >
                    Canada Addendum
                  </h2>
                </div>
                <hr />
                <div id="mainmicrosoftdataprogram_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: octobre&nbsp;2023</p>
                      <p>
                        Les dispositions supplémentaires suivantes s’appliquent
                        aux employés, aux candidats et au personnel externe
                        travaillant au Canada.
                      </p>
                      <p>
                        <strong>Mode de collecte</strong>
                      </p>
                      <p>
                        Nous recueillons des données personnelles que vous nous
                        fournissez directement (par exemple, par le biais du
                        processus de demande de poste ou dans le cadre de la
                        gestion de votre emploi ou de votre relation
                        professionnelle), ainsi que des périphériques
                        d’information nous fournissent automatiquement, comme
                        décrit ci-dessus. Nous pouvons également collecter des
                        données personnelles indirectement avec le consentement.
                        Par exemple, nous recueillons des informations de
                        vérification en arrière-plan auprès de fournisseurs de
                        filtrage en arrière-plan tiers et nous pouvons également
                        obtenir des données personnelles auprès d’agences de
                        recrutement ou de références professionnelles.
                      </p>
                      <p>
                        <strong>Transfert de données personnelles</strong>
                      </p>
                      <p>
                        Nous et nos fournisseurs de services (y compris les
                        affiliés) pouvons accéder, stocker et traiter les
                        données personnelles en dehors de votre province (y
                        compris, pour les résidents du Canada, en dehors du
                        Canada), y compris dans d’autres parties du Canada, le
                        États-Unis et d’autres juridictions étrangères où nous
                        ou nos fournisseurs de services sommes situés. Nous, nos
                        affiliés et nos fournisseurs de services pouvons
                        divulguer vos données personnelles si nous sommes requis
                        ou autorisés par la loi ou le processus juridique
                        applicable, ce qui peut inclure l’accès légal par les
                        juridictions étrangères, les forces de l’ordre ou
                        d’autres autorités gouvernementales dans les
                        juridictions dans lesquelles nous ou nos fournisseurs de
                        services opérons.
                      </p>
                      <p>
                        <strong>Rétention</strong>
                      </p>
                      <p>
                        Nous traiterons et conserverons vos données personnelles
                        aussi longtemps que nécessaire pour répondre aux
                        objectifs pour lesquels les informations ont été
                        collectées comme indiqué dans cette stratégie et pour
                        répondre à nos exigences légales ou commerciales, comme
                        indiqué dans notre planification de rétention des
                        données d’entreprise.
                      </p>
                      <p>
                        <strong>Vos droits</strong>
                      </p>
                      <p>
                        Sous réserve d’exceptions limitées en vertu de la loi
                        applicable, vous avez le droit d’accéder, de mettre à
                        jour, de corriger et de corriger les inexactitudes de
                        vos données personnelles dans notre responsabilité et
                        contrôle, et de retirer votre consentement à notre
                        collecte, à l’utilisation et à la divulgation de vos
                        données personnelles (bien qu’un employé ne puisse pas
                        retirer le consentement à la collecte des données
                        personnelles nécessaires pour administrer son emploi).
                        Vous pouvez demander l’accès, les mises à jour, la
                        correction et les corrections d’inexactitudes dans vos
                        données personnelles dans notre responsabilité ou
                        contrôler ou retirer votre consentement par e-mail{" "}
                        <a href="mailto:AskHR@microsoft.com">
                          AskHR@microsoft.com
                        </a>
                        . Nous pouvons exiger certaines données personnelles
                        pour vérifier votre identité ou l’identité de la
                        personne qui effectue la demande.
                      </p>
                      <p>
                        <strong>Comment nous contacter</strong>
                      </p>
                      <p>
                        Si vous avez des questions ou des commentaires sur cet
                        avis de confidentialité ou sur la manière dont nous ou
                        nos fournisseurs de services (y compris nos fournisseurs
                        de services en dehors du Canada) traitons vos données
                        personnelles, ou pour demander l’accès ou la correction
                        de vos données personnelles, ou pour retirer votre
                        consentement, contactez-nous par e-mail{" "}
                        <a href="mailto:AskHR@microsoft.com">
                          AskHR@microsoft.com
                        </a>
                        .
                      </p>
                      <p>
                        Vous pouvez également contacter notre responsable de la
                        confidentialité à l’aide de notre{" "}
                        <a href="https://aka.ms/privacyresponse">
                          formulaire web
                        </a>
                        .
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainTurkeydata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2 className="CollectingYourInfo" id="mainTurkeydata_Header">
                    Avis sur la Chine
                  </h2>
                </div>
                <hr />
                <div id="mainTurkeydata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: mai&nbsp;2022</p>
                      <p>
                        Cet avis sur la Chine est un supplément au{" "}
                        <a
                          href="https://privacy.microsoft.com/data-privacy-notice"
                          target="_blank"
                        >
                          Microsoft Global Data Privacy Notice
                        </a>{" "}
                        («&nbsp;DPN&nbsp;») et fournit des informations
                        supplémentaires sur le traitement des données
                        personnelles, comme l’exige la loi Information
                        Protection personnelle de Chine et ses règles et
                        réglementations d’implémentation (« loi chinoise
                        applicable »). En cas d’incohérence entre le nom de
                        principal du service et l’avis de la Chine, cet avis en
                        Chine apparaît.
                      </p>
                      <p>
                        En ce qui concerne cet avis sur la Chine, « Données
                        personnelles&nbsp;» signifie » Informations
                        personnelles&nbsp;» comme défini en vertu de la loi
                        chinoise applicable. Les données personnelles sont des
                        informations électroniques ou enregistrées d’une autre
                        manière liées à des personnes physiques identifiées ou
                        identifiables, à l’exclusion des données anonymes.
                      </p>
                      <p>
                        <strong>Données personnelles que nous traitons</strong>
                      </p>
                      <p>
                        En plus des types de données personnelles décrits sous
                        la section « Données personnelles que nous traitons »
                        dans le DPN, nous pouvons également traiter les données
                        personnelles suivantes :
                      </p>
                      <ul>
                        <li>
                          Informations d’inscription du foyer et, le cas
                          échéant, relations sociales des membres de la famille
                          ;
                        </li>
                        <li>Statut d’emploi actuel ou antérieur ;</li>
                        <li>
                          Informations sur les avantages sociaux, y compris les
                          informations nécessaires pour les contributions aux
                          caisses d’assurance sociale et de maison ;
                        </li>
                        <li>
                          Informations sur les déplacements professionnels, y
                          compris les informations de paiement de la carte de
                          crédit d’entreprise de Microsoft, ainsi que d’autres
                          informations relatives aux voyages d’affaires et aux
                          remboursements, etc.
                        </li>
                      </ul>
                      <p>
                        En vertu de la loi chinoise applicable, les types non
                        exhaustives suivants de données personnelles que nous
                        recueillons auprès de vous, si nécessaire, peuvent être
                        considérés comme des données personnelles sensibles en
                        vertu de la loi chinoise applicable :
                      </p>
                      <ul>
                        <li>
                          Données démographiques et biométriques, y compris les
                          informations d’intégrité, et :
                        </li>
                        <li>Informations financières.</li>
                      </ul>
                      <p>
                        <strong>
                          Pourquoi traitons-nous des données personnelles&nbsp;?
                        </strong>
                      </p>
                      <p>
                        Nous traitons vos données personnelles conformément à la
                        loi chinoise applicable. En outre, nous traitons vos
                        données personnelles aux fins décrites dans la section «
                        Pourquoi nous traitons les données personnelles » dans
                        le DPN et pour la gestion des ressources humaines et de
                        l’espace de travail, y compris les enquêtes et les
                        actions d’intégrité.
                      </p>
                      <p>
                        Nous collectons et utilisons des données personnelles
                        sensibles aux fins suivantes :
                      </p>
                      <ul>
                        <li>
                          Se conformer aux exigences et aux lois applicables ;
                        </li>
                        <li>
                          Pour administrer votre contrat de travail ou d’autres
                          engagements que nous vous avons pris :
                        </li>
                        <li>
                          Gestion des ressources humaines et de l’espace de
                          travail, y compris les investigations et les actions
                          judiciaires, et :
                        </li>
                        <li>
                          Gestion et opérations générales de l’entreprise.
                        </li>
                      </ul>
                      <p></p>
                      <p>
                        Nous adopterons des mesures de sécurité strictes lors du
                        traitement des données personnelles sensibles.
                      </p>
                      <p>
                        <strong>Vos droits sur vos données personnelles</strong>
                      </p>
                      <p>
                        Nous respectons vos droits en vertu de la loi chinoise
                        applicable. Dans des circonstances légales, vous pouvez
                        copier, consulter, corriger, terminer et supprimer vos
                        données personnelles. Dans certains cas, il se peut que
                        nous ne puissions pas répondre à votre demande
                        d’exercice de vos droits personnels en raison
                        d’exigences légales, de réglementations administratives
                        ou d’autres objectifs légitimes de traitement des
                        données personnelles. Vous pouvez exercer vos droits via
                        AskHR@microsoft.com.
                      </p>
                      <p>
                        <strong>
                          Transfert de données personnelles entre les frontières
                        </strong>
                      </p>
                      <p>
                        Microsoft fonctionne à l’échelle mondiale. Afin
                        d’effectuer des opérations et de gestion générales de
                        l’entreprise, d’effectuer la gestion des RH, de
                        respecter les obligations légales et, à d’autres fins
                        légales, Microsoft peut transférer les données
                        personnelles collectées à partir de vous en Chine vers
                        les entités affiliés de Microsoft en dehors de la Chine,
                        par exemple, aux États-Unis où Microsoft est basé.
                        Lorsque vos données personnelles sont transférées en
                        dehors de la Chine, nous assurerons que le transfert est
                        conforme à la loi chinoise applicable et qu’il
                        implémente les mesures appropriées et nécessaires pour
                        fournir un niveau équivalent de protection des données
                        conformément à la loi chinoise applicable.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainlearningandskillsdata">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainlearningandskillsdata_Header"
                  >
                    Union européenne, Royaume-Uni et Suisse
                  </h2>
                </div>
                <hr />
                <div id="mainlearningandskillsdata_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: octobre&nbsp;2023</p>
                      <p>
                        <strong>
                          Union européenne, Royaume-Uni et Suisse : vos droits
                          de personne concernée
                        </strong>
                      </p>
                      <p>
                        Outre les informations partagées dans la déclaration de
                        confidentialité, les employés de l’UE, du Royaume-Uni et
                        de Suisse, le personnel externe et les candidats (y
                        compris les personnes travaillant dans l’UE, le
                        Royaume-Uni et la Suisse, ou dans certaines
                        circonstances les personnes qui résident normalement
                        dans l’UE, le Royaume-Uni et la Suisse qui travaillent à
                        l’étranger) peuvent disposer de certains droits en vertu
                        des lois applicables sur la protection des données,
                        notamment l’UE et le Royaume-Uni Règlement général sur
                        la protection des données (collectivement, le “RGPD”) et
                        les lois locales qui implémentent ou complètent le RGPD
                        et la Loi fédérale suisse sur la protection des données,
                        y compris les droits relatifs aux éléments suivants :
                      </p>
                      <ol>
                        <li>
                          Demander l’accès à vos données personnelles et obtenir
                          une copie de celles-ci.
                        </li>
                        <li>
                          Demander une rectification (ou correction) des données
                          personnelles que vous avez fournies et qui sont
                          incorrectes.
                        </li>
                        <li>
                          Demander l’effacement (ou la suppression) des données
                          à caractère personnel qui ne sont plus nécessaires
                          pour atteindre les objectifs justifiant leur collecte,
                          ou qui ne doivent pas être conservées par Microsoft
                          pour d’autres finalités légitimes.
                        </li>
                        <li>
                          Restreindre le traitement de vos données personnelles
                          ou vous y opposer.
                        </li>
                        <li>
                          Le cas échéant, demander le transfert de vos données
                          personnelles vers une autre entreprise.
                        </li>
                      </ol>
                      <p>
                        Notez que certaines conditions, exceptions s’appliquent
                        à ces droits et que l’application des droits ci-dessus
                        peut varier en fonction du type de données personnelles
                        impliquées et de la base particulière de Microsoft pour
                        le traitement des données personnelles.
                      </p>
                      <p>
                        Pour demander l’exercice de l’un des droits ci-dessus,
                        contactez{" "}
                        <a href="mailto:AskHR@microsoft.com">
                          AskHR@microsoft.com
                        </a>{" "}
                        par e-mail ou par lettre à l’adresse suivante :
                      </p>
                      <p>HR Privacy</p>
                      <p>Microsoft&nbsp;Corporation</p>
                      <p>One Microsoft Way</p>
                      <p>Redmond, Washington 98052 USA (États-Unis)</p>
                      <p>
                        Nous étudierons et traiterons les demandes conformément
                        aux lois applicables en matière de protection des
                        données. Veuillez noter que nous pouvons vous demander
                        certaines informations pour nous permettre de confirmer
                        votre identité. Nous pouvons, dans certains cas, vous
                        facturer des frais raisonnables à titre de coûts
                        administratifs pour répondre à vos demande. Toutefois,
                        nous vous informerons à l’avance des frais éventuels.
                      </p>
                      <p>
                        Si nous avons besoin de votre consentement pour traiter
                        vos données personnelles, vous avez le droit de révoquer
                        ce dernier à tout moment. Notez cependant que cette
                        opération n’affectera pas la légalité du traitement
                        avant la révocation du consentement.
                      </p>
                      <p>
                        Les employés de l’UE, du Royaume-Uni et de suisse, le
                        personnel externe et les candidats (y compris les
                        personnes travaillant dans l’UE, le Royaume-Uni et la
                        Suisse, ou les personnes qui résident normalement dans
                        l’UE, le Royaume-Uni et la Suisse qui travaillent à
                        l’étranger) peuvent également poser des questions
                        directes sur la façon dont nous traitons les données
                        personnelles à notre responsable de la protection des
                        données à l’aide de notre{" "}
                        <a href="https://aka.ms/privacyresponse">
                          formulaire web
                        </a>
                        .
                      </p>
                      <p>
                        Même si nous espérons pouvoir répondre à toutes les
                        questions que vous pouvez avoir, si vous avez des
                        problèmes non résolus, vous avez également le droit de
                        vous porter plainte auprès d’une autorité de
                        surveillance de la protection des données pertinente
                        dans l’UE, le Royaume-Uni et la Suisse.
                      </p>
                      <p>
                        Pour les employés, actuels et anciens, le contrôleur de
                        vos données personnelles est l’entité Microsoft qui est
                        ou qui était votre employeur. Pour les candidats, le
                        contrôleur de vos données personnelles est l’entité
                        Microsoft pour laquelle vous avez postulé à un poste.
                        Pour le personnel externe, l’entité Microsoft à laquelle
                        vous fournissez des services est le contrôleur de vos
                        données personnelles. Microsoft Corporation est
                        également le contrôleur des données personnelles des
                        objets de données mentionnés ci-dessus. Toutes les
                        requêtes relatives à la confidentialité pour votre
                        contrôleur de données doivent être dirigées vers{" "}
                        <a href="mailto:AskHR@microsoft.com">
                          AskHR@microsoft.com
                        </a>{" "}
                        ou par lettre à l’adresse suivante :
                      </p>
                      <p>HR Privacy</p>
                      <p>Microsoft&nbsp;Corporation</p>
                      <p>One Microsoft Way</p>
                      <p>Redmond, Washington 98052 USA (États-Unis)</p>
                      <p>
                        En outre, les coordonnées du responsable du traitement
                        de vos données sont indiquées dans votre contrat ou
                        votre demande d'emploi.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainMDPaddendum">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainMDPaddendum_Header"
                  >
                    Addenda à l’Avis sur la protection des données concernant
                    les données relatives aux formations et aux compétences
                  </h2>
                </div>
                <hr />
                <div id="mainMDPaddendum_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: juin&nbsp;2021</p>
                      <p>
                        Cet addendum concerne les{" "}
                        <strong>
                          Données relatives aux formations et aux compétences
                        </strong>{" "}
                        des employés et du personnel externe de Microsoft, que
                        Microsoft traite pour diverses raisons, en respectant la
                        réglementation locale en vigueur, sa propre politique
                        interne en la matière, les conditions d’utilisation de
                        tiers (par exemple, lorsque les données relatives aux
                        formations et aux compétences sont fournies par des
                        tiers), ainsi que les exigences contractuelles
                        applicables aux tiers.
                      </p>
                      <p>
                        Les données relatives aux formations et aux compétences
                        sont des informations sur vos activités de
                        perfectionnement professionnel, telles que les
                        formations, les accomplissements, les compétences et les
                        intérêts associés. Les sources de données liées aux
                        formations et aux compétences incluent des informations
                        sur&nbsp;:
                      </p>
                      <ul>
                        <li>
                          Vos interactions avec les sites web de Microsoft
                          Learning, tels que Microsoft Learn ou LinkedIn
                          Learning, lorsque vous vous authentifiez avec votre
                          compte d’employé Microsoft.
                        </li>
                        <li>
                          Il s’agit des formations internes Microsoft, des cours
                          ou d’autres offres proposées par Microsoft, que vous
                          pouvez suivre pour développer des compétences
                          professionnelles. Ces offres peuvent être
                          facultatives, encouragées, attendues ou même
                          obligatoires. Elles peuvent être fournies en direct,
                          en ligne ou par le biais d’enregistrements audio et
                          vidéo. Enfin, elles peuvent s’adresser à un large
                          public ou conçues spécialement pour votre entreprise,
                          votre poste ou votre fonction. Voici quelques
                          exemples&nbsp;: l’apprentissage des règles
                          déontologiques régissant les activités commerciales de
                          Microsoft, les offres de formations LinkedIn Learning
                          réservées aux employés de Microsoft, et les séminaires
                          proposés via des portails spécialisés pour toute
                          l’entreprise, certains départements ou des équipes
                          particulières.
                        </li>
                        <li>
                          Les formations de tiers ou les cours proposés par
                          Microsoft, ou liés à votre compte d’employé Microsoft,
                          ou que vous choisissez de partager avec Microsoft.
                          Contrairement aux formations internes indiquées plus
                          haut, ces formations sont proposées par des tiers, pas
                          par Microsoft, ou sont fournies par le biais de
                          services tels que LinkedIn ou LinkedIn Learning. Ces
                          formations peuvent être suivies par l’intermédiaire de
                          sites web externes, de cours à distance ou être
                          dispensées (même en interne) par des ressources
                          tierces. Tout comme les formations internes, ces
                          formations tierces peuvent être ciblées de manière
                          globale ou délimitée pour votre activité, poste ou
                          fonction et peut être disponible via des sites web
                          commerciaux ou s’adressant au consommateur. Il peut
                          s’agir, par exemple, d’offres LinkedIn Learning ou de
                          cours proposés par des tiers tels que Dale Carnegie ou
                          d’autres.
                        </li>
                        <li>
                          Les certifications et succès, tels que des
                          certifications validées par Microsoft ou par des
                          tiers, que vous menez à bien et choisissez de
                          partager. Plusieurs tâches, rôles ou fonctions peuvent
                          nécessiter des certifications spécifiques. Si c’est le
                          cas, vous serez préalablement informé de ces
                          exigences. Si des certifications sont obligatoires,
                          vous pouvez être amené à partager des informations sur
                          leur obtention.
                        </li>
                        <li>
                          Les compétences que vous identifiez ou qui peuvent
                          être déduites de vos activités de formation ou
                          professionnelles.
                        </li>
                        <li>
                          La participation à des événements Microsoft, tels que
                          Ready, Build et les hackatons.
                        </li>
                        <li>
                          Les intérêts d’évolution, tels que les expériences ou
                          compétences que vous indiquez vouloir développer lors
                          de mises en relation ou d’autres contextes, ainsi que
                          les contenus ou documents que vous consultez dans le
                          cadre de votre développement ou perfectionnement
                          professionnel, planification de carrière, renforcement
                          de compétences et d’autres opportunités de formations.
                        </li>
                        <li>
                          Le développement en fonction du poste tel que des
                          activités pratiques ou basées sur votre expérience,
                          qui vous permettent d’acquérir des compétences pour
                          votre poste.
                        </li>
                      </ul>
                      <p>
                        Microsoft peut traiter différents types de données parmi
                        les sources ci-dessus, notamment (mais sans s’y
                        limiter)&nbsp;:
                      </p>
                      <ul>
                        <li>
                          Vos données démographiques et coordonnées, telles que
                          votre nom, votre adresse, l’intitulé de votre poste,
                          votre niveau dans l’organigramme de l’entreprise, vos
                          compétences, etc.
                        </li>
                        <li>
                          Les données relatives à votre ponctualité, vos
                          performances et vos réussites professionnelles ;
                        </li>
                        <li>
                          Vos commentaire sur un événement, une formation ou un
                          cours particulier ;
                        </li>
                        <li>
                          L’analyse de vos interactions avec un site ou un
                          service de formation ou d’apprentissage ;
                        </li>
                        <li>
                          Les données concernant les compétences que vous
                          fournissez ou qui sont observées ;
                        </li>
                        <li>
                          Des photos, vidéos ou enregistrement (vidéo et audio)
                          de l’activité ou de l’événement de formation.
                        </li>
                      </ul>
                      <p>
                        Microsoft collecte également des données relatives aux
                        formations et aux compétences dans plusieurs contextes.
                        Par exemple, Microsoft collecte des données relatives
                        aux formations et aux compétences quand&nbsp;:
                      </p>
                      <ul>
                        <li>
                          vous les fournissez, notamment en partageant vos
                          objectifs de perfectionnement professionnel avec votre
                          responsable, en vous inscrivant à une liste de
                          diffusion interne de Microsoft, un groupe affilié à un
                          certificat ou une compétence professionnelle, ou
                          lorsque vous mettez à jour votre profil, notamment en
                          ajoutant des badges désignant des réalisations
                          professionnelles&nbsp;;
                        </li>
                        <li>
                          vous autorisez un tiers à les fournir, par exemple,
                          lorsque vous dirigez un organisme scolaire ou
                          professionnel et que vous souhaitez partager vos
                          réalisations professionnelles avec Microsoft&nbsp;;
                        </li>
                        <li>
                          vous vous inscrivez et participez à des activités
                          Microsoft Learning, comme Microsoft Ready, Microsoft
                          Build, ou un hackathon&nbsp;;
                        </li>
                        <li>
                          vous utilisez les services de formation réservés aux
                          employés et/ou au personnel externe de Microsoft (par
                          exemple, lorsque vous consultez des contenus de
                          perfectionnement professionnel ou interagissez avec
                          des modules d’apprentissage&nbsp;;
                        </li>
                        <li>
                          et lorsque vous utilisez votre authentification de
                          compte d’employé Microsoft pour accéder à des services
                          d’apprentissage et/ou de formation, tels que Microsoft
                          Learn ou LinkedIn Learning (sous réserve des
                          conditions d’utilisation applicables au site web
                          d’hébergement et de toute obligation contractuelle que
                          Microsoft a contractée pour accéder à ces données).
                        </li>
                      </ul>
                      <p>
                        Microsoft utilise les données relatives aux formations
                        et aux compétences à des fins variées, lesquelles
                        peuvent impliquer le traitement automatique de telles
                        données à l’aide d’applications de Machine Learning et
                        d’intelligence artificielle, comme dans le cas du
                        traitement en langage naturel.
                      </p>
                      <ol>
                        <li>
                          <p>
                            Pour gérer la relation de travail ou contractuelle
                            qui nous lie à vous, y compris en ce qui concerne
                            les opportunités d’évolution de votre carrière
                          </p>
                          <p>
                            Nous traitons les données relatives aux formations
                            et aux compétences pour gérer la relation de travail
                            ou contractuelle qui nous lie à vous. Cela inclut la
                            satisfaction de nos obligations et engagements
                            vis-à-vis de vous. Le fait de ne pas fournir les
                            données relatives à vos formations et compétences
                            lorsque vous y êtes invité peut nous empêcher de
                            satisfaire ces obligations et engagements, mais
                            aussi de respecter nos obligations légales. Par
                            exemple, Microsoft utilise des données relatives aux
                            formations et aux compétences pour&nbsp;:
                          </p>
                          <ul>
                            <li>
                              Vérifier que vous avez terminé les activités de
                              formation requises pour votre poste ou tel
                              qu’exigé par la réglementation en vigueur&nbsp;;
                            </li>
                            <li>
                              Faciliter, sur votre demande, votre
                              perfectionnement et votre planification
                              professionnels&nbsp;;
                            </li>
                            <li>
                              Examiner, récompenser et améliorer les
                              performances et l’évolution de carrière des
                              employés&nbsp;;
                            </li>
                            <li>
                              Identifier les opportunités de carrière et
                              d’évolution pour les employés&nbsp;;
                            </li>
                            <li>
                              Déterminer les ressources appropriées pour une
                              opportunité ou un scénario de support client
                              particulier&nbsp;;
                            </li>
                            <li>
                              Évaluer le potentiel d’évolution d’un
                              employé&nbsp;;
                            </li>
                            <li>
                              Vérifier que vous avez suivi une formation payée
                              ou remboursée par Microsoft&nbsp;;
                            </li>
                            <li>
                              Vous aider à identifier le contenu ou les
                              documents susceptibles de correspondre à vos
                              intérêts.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            Pour fournir nos produits et services et les
                            améliorer
                          </p>
                          <p>
                            Nous traitons des données relatives aux formations
                            et aux compétences pour fournir et améliorer nos
                            produits et services. Par exemple, lorsque vous vous
                            inscrivez à une formation ou à une certification
                            Microsoft, nous utilisons les données relatives à
                            vos formations et compétences pour déterminer si
                            vous avez suivi cette formation ou certification et,
                            le cas échéant, si vous l’avez réussie.
                          </p>
                          <p>
                            Nous traitons des données relatives aux formations
                            et aux compétences pour améliorer nos produits et
                            services. Par exemple, nous pouvons&nbsp;:
                          </p>
                          <ul>
                            <li>
                              analyser des données relatives aux formations et
                              aux compétences pseudonymisées, pour déterminer
                              les activités de formation les plus fréquentes
                              parmi les nouveaux employés ou les employés
                              occupant certains postes&nbsp;;
                            </li>
                            <li>
                              associer des données relatives aux formations et
                              aux compétences à d’autres données de Business
                              Intelligence (ou «&nbsp;aide à la décision&nbsp;»)
                              pour identifier et évaluer, sur une base agrégée,
                              l’efficacité des produits et services de
                              formation. Par exemple, nous pouvons vérifier si
                              certaines activités de formation permettent
                              d’accroître la satisfaction de nos clients,
                              d’améliorer la sécurité de nos employés, de
                              réduire les incidents de sécurité ou d’influencer
                              les opportunités de perfectionnement professionnel
                              ou les performances des employés&nbsp;;
                            </li>
                            <li>
                              ou exploiter des retours et commentaires sur les
                              activités de formations pour améliorer nos
                              produits et services. Par exemple, en analysant
                              des résultats agrégés d’examens de certification
                              Azure ou des commentaires reçus après un événement
                              de formation, nous pouvons mettre à jour des
                              informations nous permettant d’améliorer Azure.
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p></p>
                          <p>Pour d’autres finalités légitimes</p>
                          <p>
                            Nous traitons les données relatives aux formations
                            et aux compétences pour d’autres finalités
                            légitimes, telles que&nbsp;:
                          </p>
                          <ul>
                            <li>
                              Le bon déroulement de nos activités, la mise en
                              place d’une aide à la décision, la réalisation
                              d’audits et la création de rapports, la gestion de
                              la sécurité de notre réseau et de nos systèmes
                              informatiques, ainsi que la prestation de services
                              pour nos employés et l’amélioration de ces
                              services.
                            </li>
                            <li>
                              Nos investigations, lorsque nous soupçonnons une
                              infraction ou une violation de nos politiques
                              internes.
                            </li>
                            <li>
                              Celles qui nous sont permises lorsque nous
                              bénéficions de votre consentement obtenu dans le
                              respect de la législation en vigueur.
                            </li>
                            <li>
                              L’obligation de nous conformer aux lois et
                              réglementations, y compris celles régissant la
                              collecte et la communication de données
                              personnelles dans un cadre juridique (par exemple,
                              pour établir un salaire minimal, définir le temps
                              de travail, répondre aux obligations fiscales,
                              assurer l’intégrité et la sécurité de notre
                              personnel et de nos locaux, respecter les lois en
                              matière de lutte contre les discriminations,
                              organiser des déménagements et respecter les
                              droits de la personne concernée), sous
                              autorisation judiciaire, ou pour exercer ou
                              défendre les droits de Microsoft.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="page m-b-bl" id="mainChinaNotice">
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainChinaNotice_Header"
                  >
                    Addenda concernant Microsoft Data Program (MDP)
                  </h2>
                </div>
                <hr />
                <div id="mainChinaNotice_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: juin&nbsp;2021</p>
                      <p>
                        Cet addenda s’applique au programme de gestion des
                        données Microsoft (ou MDP, pour «&nbsp;Microsoft Data
                        Program&nbsp;») et aux données liées aux activités de
                        notre entreprise et traitées dans le cadre de ce
                        programme, à des fins de débogage, de test, de
                        développement et d’amélioration de nos nouveaux produits
                        et services, ainsi que nos produits et services
                        existants (ces données sont désignées collectivement
                        sous le terme «&nbsp;Données MDP&nbsp;»). Les Données
                        MDP peuvent être utilisées à des fins de recherche
                        scientifique et pour former des modèles d’apprentissage
                        automatique et d’intelligence artificielle. Le MDP et
                        les conditions du présent addenda s’appliquent
                        uniquement aux employés de Microsoft. Les données du
                        personnel externe et des candidats sont exclues du MDP.
                        Si vous souhaitez en savoir plus sur les termes
                        spécifiques et le champ d’application du MDP, veuillez
                        consulter la{" "}
                        <a
                          href="https://aka.ms/msftdp"
                          title="Page de présentation du Microsoft Data Program"
                        >
                          page de présentation du programme
                        </a>
                        . Les employés peuvent choisir de limiter leur
                        participation au MDP à tout moment, sans préjudice, en
                        cliquant ici{" "}
                        <a href="http://aka.ms/MDPOptOut">
                          http://aka.ms/MDPOptOut
                        </a>
                        .
                      </p>
                      <p>
                        L’objectif principal du MDP est le traitement des
                        données ou des informations transmises, créées ou
                        échangées par les employés de Microsoft à l’aide des
                        systèmes internes, logiciels, services et ressources de
                        Microsoft, dans le cadre de leur travail. Microsoft
                        déploie des efforts raisonnables pour implémenter des
                        contrôles afin d’exclure, si possible, les données non
                        liées à l’activité à partir du champ d’application du
                        MDP. Même si ces contrôles sont destinés à limiter le
                        champ d’application du MDP au traitement de données
                        liées aux activités de Microsoft (comme décrit plus en
                        détail dans la{" "}
                        <a
                          href="https://aka.ms/msftdp"
                          title="Page de présentation du Microsoft Data Program"
                        >
                          page de présentation du programme
                        </a>
                        ), il est possible que le MDP traite par accident
                        certains contenus personnels des employés qui sont
                        créés, stockés ou transmis par le biais de ressources et
                        systèmes détenus ou fournis par Microsoft. Lorsque que
                        cela se produit, Microsoft poursuit ses efforts
                        raisonnables pour affiner les contrôles afin de mieux
                        exclure de telles données à l’avenir. Le traitement des
                        données par le MDP respecte constamment les exigences
                        établies pour ce dernier, ainsi que les politiques
                        internes de Microsoft (y compris notre{" "}
                        <a href="https://microsoft.sharepoint.com/sites/MS_Policy/Pages/Policy.aspx?policyid=MSPolicy-7777">
                          Politique d’utilisation responsable de la technologie
                        </a>
                        ), ainsi que la législation locale.
                      </p>
                      <p>
                        Les sources de Données MDP incluent, sans s’y limiter,
                        les e-mails et les informations de calendrier dans
                        Exchange, les fichiers stockés dans OneDrive Entreprise,
                        le contenu des enregistrements de réunions, les appels
                        vocaux collectés sur les appareils professionnels, les
                        messages dans Yammer et Teams, le contenu des sites
                        SharePoint, les données de diagnostic provenant
                        d’appareils professionnels, les données de recherches,
                        les données de commentaires sur les produits et
                        services, et les applications métiers internes telles
                        que les applications développées afin de prendre en
                        charge les processus commerciaux (par exemple, MSX). Il
                        s’agit d’exemples représentatifs et non exhaustifs des
                        types de données liées aux activités de Microsoft
                        traitées dans le cadre du MDP. Pour obtenir des
                        informations à jour sur les dernières évolutions du MDP,
                        veuillez consulter la{" "}
                        <a
                          href="https://aka.ms/msftdp"
                          title="Page de présentation du Microsoft Data Program"
                        >
                          page de présentation du programme
                        </a>
                        .
                      </p>
                      <p>
                        Outre les données de contenus provenant des sources
                        évoquées plus haut, le cadre du MDP permet à Microsoft
                        de traiter d’autres types de données provenant de ces
                        mêmes sources, notamment (sans s’y limiter)&nbsp;:
                      </p>
                      <ul>
                        <li>
                          <p>
                            <em>
                              Des données démographiques de base, y compris, par
                              exemple, votre nom et votre alias, etc.
                            </em>
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>
                              Des métadonnées de contenus, telles que les
                              informations de date et d’heure, les signaux liés
                              à la création et la modification des données, des
                              documents et des titres de réunions, etc.
                            </em>
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>
                              Des données de télémétrie, telles que les données
                              relatives à l’utilisation des produits et des
                              fonctionnalités, associées aux services et types
                              de contenu ci-dessus, ou des données relatives aux
                              ordinateurs, comme l’historique des versions des
                              logiciels, le type d’ordinateur, la version du
                              système d’exploitation, etc.
                            </em>
                          </p>
                        </li>
                      </ul>
                      <p>
                        L’utilisation des Données MDP par Microsoft est fondée
                        par l’intérêt légitime de Microsoft dans le cadre de
                        l’utilisation de ses propres données métier à des fins
                        professionnelles, car cette utilisation dépasse
                        nettement l’intérêt de nos employés pour la
                        confidentialité de telles données liées à nos activités.
                        Microsoft peut traiter certaines Données MDP selon le
                        consentement de ses employés, dans la mesure où&nbsp;:
                        (1) la protection des données personnelles de l’employé
                        serait plus importante que l’intérêt de Microsoft pour
                        ce traitement&nbsp;; et (2) la législation locale oblige
                        Microsoft à obtenir ce consentement avant tout
                        traitement. Le consentement est indispensable au
                        traitement des données dans le cadre du Microsoft Data
                        Program. C’est pourquoi Microsoft s’engage à ce que ce
                        consentement soit systématiquement volontaire et
                        éclairé. De même, si un employé refuse de donner son
                        consentement ou décide de le révoquer ultérieurement et
                        n’obtient aucun avantage spécifique en choisissant de
                        participer ou de fournir des données au MDP, il ne
                        subira aucun préjudice.
                      </p>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="page m-b-bl"
                id="mainMicrosoftGlobalDataPrivacyNotice"
              >
                <div className="div_heading_OnePSTemplete header-small">
                  <h2
                    className="CollectingYourInfo"
                    id="mainMicrosoftGlobalDataPrivacyNotice_Header"
                  >
                    Turquie
                  </h2>
                </div>
                <hr />
                <div id="mainMicrosoftGlobalDataPrivacyNotice_ShortDescription">
                  <label className="Description">
                    <span>
                      <p>Dernière mise à jour&nbsp;: juin&nbsp;2021</p>
                      <p>
                        <strong>
                          Avis sur la protection des données personnelles de nos
                          employés en Turquie
                        </strong>
                      </p>
                      <p>
                        En ce qui concerne les activités de traitement des
                        données relatives aux employés, au personnel externe et
                        aux personnes postulant chez Microsoft en Turquie,
                        Microsoft Bilgisayar Yazılım Hizmetleri Limited Şirketi
                        agit en tant que responsable du traitement des données,
                        dans le cadre de la Loi sur la protection des données
                        personnelles N°&nbsp;6698 (la{" "}
                        <strong>«&nbsp;Loi&nbsp;»</strong>).
                      </p>
                      <p>
                        En ce qui concerne les activités de traitement des
                        données relatives aux employés, aux candidats et au
                        personnel extérieur du Bureau de liaison (MEA HQ) en
                        Turquie, Microsoft Ireland Research ULC agit en tant que
                        responsable du traitement des données à des fins
                        légales.
                      </p>
                      <p>
                        Outre les informations partagées ci-dessus, nous
                        traitons les données personnelles qui vous concernent
                        afin de gérer nos contrats, audits et procédures
                        éthiques.
                      </p>
                      <p>
                        Nous pouvons collecter ces données depuis vos e-mails,
                        vos appels téléphoniques, votre usage de services web,
                        votre messagerie, vos courriers, les formulaires
                        physiques et en ligne que vous remplissez, ainsi que par
                        des photographies et des enregistrements vidéo lors
                        d’événements auxquels vous participez, dans des
                        environnements physiques et électroniques.
                      </p>
                      <p>
                        Le traitement de ces données personnelles doit respecter
                        les fondements juridiques suivants&nbsp;: il doit être
                        envisagé dans le cadre des lois et respecter les
                        obligations légales. Il doit aussi être nécessaire à
                        l’établissement, l’exercice et la protection d’un droit,
                        ainsi que la conclusion et l’exécution d’un accord, la
                        protection des intérêts légitimes du responsable du
                        traitement des données et, le cas échéant, dépendre de
                        votre consentement explicite, comme spécifié dans le
                        champ d’application de la Loi.
                      </p>
                      <p>
                        En tant que personne concernée par le traitement des
                        données, vous bénéficiez des droits énoncés à
                        l’article&nbsp;11 de la Loi. Conformément au communiqué
                        relatif aux principes et procédures applicables aux
                        demandes adressées aux responsables du traitement des
                        données, et devant être conclues dans un délai de
                        30&nbsp;jours, vous pouvez transmettre vos demandes
                        concernant vos droits conformément à l’article 11 de la
                        Loi, par les moyens suivants&nbsp;:
                      </p>
                      <ul>
                        <li>
                          Adresse e-mail enregistrée (KEP)&nbsp;:{" "}
                          <a href="mailto:microsoft@hs02.kep.tr">
                            microsoft@hs02.kep.tr
                          </a>
                        </li>
                        <li>
                          Adresse e-mail&nbsp;:{" "}
                          <a href="mailto:AskHR@microsoft.com">
                            AskHR@microsoft.com
                          </a>{" "}
                          (
                          <em>
                            si votre adresse e-mail est enregistrée dans nos
                            systèmes, vous pouvez envoyer directement votre
                            demande. Si votre adresse e-mail n’est pas
                            enregistrée dans les systèmes du responsable du
                            traitement des données, vous devez apposer une
                            signature électronique sécurisée ou une signature
                            mobile à votre demande
                          </em>
                          ).
                        </li>
                        <li>
                          Adresse pour les demandes écrites&nbsp;: Levent mAh.
                          Aydın Sok. N°&nbsp;: 7 Nisbetiye, 34340
                          Beşiktaş/Istanbul, Türkiye (Turquie).
                        </li>
                      </ul>
                    </span>
                  </label>
                </div>
                <div className="wrapperModuleFooter">
                  <div className="TopNavigation">
                    <a className="lnkGoTop" href="#" role="button">
                      <span aria-hidden="true">
                        <i className="icon-up m-l-md"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="CG__container__form">
          <Form className="CGU__container__form mb-0">
            {!isMobile && (
              <Button
                variant="primary"
                className="py-3 py-md-2"
                onClick={onClickNext}
              >
                {t("landing.CGU.buttonText")}
              </Button>
            )}
          </Form>
        </div>
      </div>
      {isMobile && (
        <div className="CGU__container__mobile--actions">
          <Button
            variant="primary"
            className="py-3 py-md-2 w-100"
            onClick={onClickNext}
          >
            {t("landing.CGU.buttonText")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(CGU);
